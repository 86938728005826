"use client";

import UnknownError from "@/templates/default/elements/Error/UnknownError";

interface ErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function Error({ error, reset }: ErrorProps) {
  return <UnknownError />;
}
