import React from "react";
import { PrimaryButton } from "@/components/Button";
import { C404 } from "@/assets/Icons/C404";
import { RightArrow } from "@/assets/Icons/RightArrow";
import { Section } from "@/shared/layout";
import { FormattedMessage } from "@/i18n/i18n-client";

export const SvgWrapper = ({ children }) => (
  <div className="[&>svg]:w-72 md:[&>svg]:w-auto">{children}</div>
);

export const StyledHead = ({ children }) => (
  <h1 className="leading-tight text-2xl font-semibold text-[##1a1a1a]">
    {children}
  </h1>
);

export const StyledParagraph = ({ children }) => (
  <p className="text-xs md:text-sm leading-snug font-normal color-[#5f738c]">
    {children}
  </p>
);

export default function PageNotFoundError() {
  return (
    <Section>
      <div className="h-screen flex flex-col justify-center items-center">
        <StyledHead>
          <FormattedMessage defaultMessage="Page not found!" id="1vjhyI" />
        </StyledHead>
        <SvgWrapper>
          <C404 />
        </SvgWrapper>
        <StyledParagraph>
          <FormattedMessage
            defaultMessage="Sorry we’re unable to find the page you’re looking for."
            id="nZJOm8"
          />
        </StyledParagraph>
        <PrimaryButton href="/shop" suffixIcon={<RightArrow />}>
          <FormattedMessage defaultMessage="Go back to Shop" id="Bs1bk+" />
        </PrimaryButton>
      </div>
    </Section>
  );
}
