import React from "react";
import { C500 } from "@/assets/Icons/C500";
import { RightArrow } from "@/assets/Icons/RightArrow";
import { Section } from "@/shared/layout";
import { StyledHead, StyledParagraph, SvgWrapper } from "./PageNotFound";
import { PrimaryButton } from "@/components/Button";
import { FormattedMessage } from "@/i18n/i18n-client";

function UnknownError() {
  return (
    <Section>
      <div className="h-screen flex flex-col justify-center items-center">
        <SvgWrapper>
          <C500 />
        </SvgWrapper>
        <StyledHead>
          <FormattedMessage
            defaultMessage="Something went wrong!"
            id="/WMCDd"
          />
        </StyledHead>
        <StyledParagraph>
          <FormattedMessage
            defaultMessage="Sorry we’re unable to find the page you’re looking for."
            id="nZJOm8"
          />
        </StyledParagraph>
        <PrimaryButton href="/shop" suffixIcon={<RightArrow />}>
          <FormattedMessage defaultMessage=" Go back to Shop" id="Io1yQT" />
        </PrimaryButton>
      </div>
    </Section>
  );
}

export default UnknownError;
